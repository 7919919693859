.api-custom-loader {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 16px solid #0000;
  border-right-color: #718CEB97;
  position: relative;
  animation: s4 1s infinite linear;
}
.api-custom-loader:before,
.api-custom-loader:after {
  content: "";
  position: absolute;
  inset:-16px;
  border-radius: 50%;
  border:inherit;
  animation:inherit;
  animation-duration: 2s;
}
.api-custom-loader:after {
  animation-duration: 4s;
}

@keyframes s4 {
  100% {transform: rotate(1turn)}
}

.api-custom-loader {
  position: fixed;
  top: 50%;
  left: 50%;
  translate : -50% -50%;
  z-index: 9999;
}

.custom-loader-backdrop{
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
}